import { Video, VideoProps } from '@collab/atoms';

import styles from './HeroVideo.module.css';

const HeroVideo: React.FC<VideoProps> = (props) => (
  <div className={styles.wrapper}>
    <Video
      aspectRatio={{ default: [21, 9], mobile: [4, 3] }}
      controls="enabled"
      loading="eager"
      loop={false}
      {...props}
    />
  </div>
);

export default HeroVideo;
